// // useSocket.ts
// import {ref, onUnmounted, Ref} from 'vue';
// import { io, Socket } from 'socket.io-client';



// export function useSocket(): SocketReturn {
//     // Initialize socket at the top level so it's accessible immediately
//     const socket = io(import.meta.env.VITE_BACKEND_BASE_URL || 'http://localhost:3000', {
//         withCredentials: true
//     });
//
//     const isConnected = ref(false);
//
//     socket.on('connect', () => {
//         isConnected.value = true;
//         console.log('Connected to socket', socket.id);
//     });
//
//     socket.on('connect_error', (error) => {
//         console.error('Socket connection error:', error);
//     });
//
//     onUnmounted(() => {
//         socket.disconnect();
//     });
//
//     return {
//         socket,
//         isConnected
//     };
// }

// useSocket.ts
import {Ref, ref} from 'vue';
import { io, Socket } from 'socket.io-client';

interface SocketReturn {
    socket: Socket | null;
    isConnected: Ref<boolean>;
}

let socket: Socket | null = null;  // Single socket instance
const isConnected = ref(false);

export function useSocket(): SocketReturn {
    // Only create the socket if it doesn't exist
    if (!socket) {
        socket = io(import.meta.env.VITE_BACKEND_BASE_URL || 'http://localhost:3000', {
            withCredentials: true
        });

        socket.on('connect', () => {
            isConnected.value = true;
            console.log('Connected to socket', socket?.id);
        });

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });
    }

    return {
        socket,
        isConnected
    };
}