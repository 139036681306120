// stores/authStore.js
import { defineStore } from 'pinia';
import backendCall from "../services/AxiosService";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import router from "@/router";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isAuthenticated: false,
        user: null,
        authCheckPromise: null
    }),
    actions: {
        async checkAuthStatus() {
            if (this.authCheckPromise) {
                return this.authCheckPromise;
            }

            this.authCheckPromise = backendCall.get('/auth/check')
                .then(response => {
                    this.isAuthenticated = !!response.data.user;
                    this.user = response.data.user;
                })
                .catch(() => {
                    this.isAuthenticated = false;
                    this.user = null;
                })
                .finally(() => {
                    this.authCheckPromise = null;
                });

            return this.authCheckPromise;
        },
        async login(credentials: { username: string, password: string }) {
            try {
                const response = await backendCall.post('/auth/login', credentials);
                this.isAuthenticated = true;
                this.user = response.data.user;
                Sentry.setUser({
                    id: response.data.user.id,
                    username: response.data.user.username,
                    roles: response.data.user.roles,
                });
                return response;
            } catch (error) {
                // Log all error details
                // console.error('Login error:', {
                //     status: error.response?.status,
                //     data: error.response?.data,
                //     message: error.message
                // });

                // Re-throw the error so it can be handled by the component
                throw error;
            }
        },
        async logout() {
            await router.push('/login');
            await axios.get(`${API_BASE_URL}auth/logout`, {withCredentials: true});
            this.isAuthenticated = false;
            this.user = null;
            Sentry.setUser(null);

        }
    }
});