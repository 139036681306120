<script setup lang="ts">
import Toast from "primevue/toast";
import { watch } from "vue";
import { useRouter } from 'vue-router'
import { useAuthStore } from './stores/authStore'

const router = useRouter()
const authStore = useAuthStore()

watch(() => authStore.isAuthenticated, (newValue, oldValue) => {
  if (!newValue && oldValue) {
    // User has been logged out
    router.push('/login')
  }
})
</script>

<template>
  <Toast/>
  <RouterView></RouterView>

</template>

<style scoped>

</style>
